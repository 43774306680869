@charset "UTF-8";


@import "_setting.scss";

/* CSS Document
font-family: 'Julius Sans One', sans-serif;
 */

body {
	font-family: $gothic;
	text-align: center;
	-webkit-text-size-adjust: 100%;
}
*{
	margin:0;
	padding:0;
}
img{
	vertical-align: bottom;
	max-width:100%;
    height:auto;
}
a img {
	border:none;
}
a {
	color:#069;
}
a:hover{
	text-decoration:underline;
    opacity:0.7;
}

.clearfix:after {  
    visibility: hidden;  
    display: block;  
    font-size: 0;  
    content: " ";  
    clear: both;  
    height: 0;  
    }  
* html .clearfix             { zoom: 1; } /* IE6 */  
*:first-child+html .clearfix { zoom: 1; } /* IE7 */  
li {
	list-style:none;
}
i {
	padding:0 5px;
}
.pc {
	
}
.sp {
	display:none;
}
@include media-tab {
img{
	border: 0;
max-width: 100%;
    height: auto;
}
 

.pc {
	display:none;
}
.sp {
	display:block;
}
}
/*PC改行加える*/
.br-pc{
	display:block;
}
 /*PC改行無効*/
.br_sp{
	display:none;
}
.br_ssp{
	display:none;
}
@include media-tab {
	/*SP改行無効*/
.br-pc{
	display:none;
}
/*SP改行加える*/
.br_sp { display:block; }
.br_ssp{
	display:none; 
}
}
@include media-sp {
	/*SP改行無効*/
.br-pc{
	display:none;
}
/*SP改行加える*/
.br_sp { display:block; }
.br_ssp{
	display:block; 
}
}
.b5{
margin-bottom:5px !important;
}

.b10{
margin-bottom:10px !important;
}

.b15{
margin-bottom:15px !important;
}

.b20{
margin-bottom:20px !important;
}

.b30{
margin-bottom:30px !important;
}

.b40{
margin-bottom:40px !important;
}

.b50{
margin-bottom:50px !important;
}
/******** wrapper *****/
.wrapper {
	width:1000px;
	margin:0 auto;
}
@include media-tab {
	.wrapper {
	width:86%;
	margin:0 auto;
}
}

/******** スマホメニュー用2 *****/



#sp_menu{
	display:none;
}
#sp_menu_content {
	display:none;
}
@include media-tab {
	
#sp_menu{
	position:fixed;
	display:block;
	/*top:0;*/
	bottom:0;
	right:0;
	width:50px;
	z-index:110;
}
#sp_menu_content {
	position:fixed;
	z-index:101;
	bottom:0;
	right:0;
	width:100%;
	text-align:left;
	background-color:rgba(0,0,0,0.8);
filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#88000000,EndColorStr=#88000000);/*IE8以下用*/
}
}
#sp_menu_content a {
	color:#fff;
	text-decoration:none;
}
#sp_menu_content li {
box-sizing: border-box;
	float:left;
	width:33.33%;
	padding:2%;
	border-bottom:1px solid #666;
	font-size:90%;
}
#sp_menu_content li.all {
	float:none;
	width:96%;
}
#sp_menu_content li ul {
	margin:5px 0 0 10px;
}
#sp_menu_content li ul li {
	font-size:80%;
	padding:5px 0;
	border:none;
	float:left;
	width:25%;
}
@include media-sp {
	#sp_menu_content li {
		float:none;
	padding:10px 3%;
	width:94%;
	border-bottom:1px solid #666;
	font-size:90%;
}
	#sp_menu_content li ul li {
	font-size:80%;
	padding:5px 0;
	border:none;
	float:left;
	width:50%;
}
}
/********************* スマホ用ONLINESHOPバナー *********************/
#sp_onlineshop{
	display:none;
}
@include media-tab {
#sp_onlineshop{
	position:fixed;
	display:block;
	/*top:0;*/
	bottom:0;
	right:50;
	width:100%;
	z-index:100;
	background:#bbb;
}
#sp_onlineshop a{
	font-family: $font-eng;
	color:#000;
	text-decoration:none;
	font-size:105%;
	letter-spacing: 0.2em;
	line-height: 3em;
	padding:20px 0;
}
}
/********************* LOADING *********************/

#loader {
	width:100px;
	height:40px;
	position: fixed;
	top:45%;
	left: 0;
	right:0;
	margin:auto;
	/*z-index: 100;*/
	 display: none;
    /*width: 20px;
    height: 20px;
   
    
    _position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px; 
    margin-left: -50px; 
    */
}

.animation {
    margin:50px auto;
    
    height: 40px;
    width: 100px;
    animation: rotate 4s;  
    animation-iteration-count: infinite;   
    /* webkit用animation */
    -webkit-animation: rotate 4s;
    -webkit-animation-iteration-count: infinite;
	
}
@keyframes rotate {
    0% { transform: rotateY(0deg); }
    100% { transform: rotateY(360deg); } 
}
@-webkit-keyframes rotate {
     /* webkit用keyframes */ 
    0% { -webkit-transform: rotateY(0deg); }
    100% { -webkit-transform: rotateY(360deg); }
}
 
#fade {
    width: 100%;
    height: 100%;
    display: none;
    background-color: #FFFFFF;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 50;
}

/********************* HEADER *********************/

/*** Black ***/
header #head_bar {
	background:#000;
	color:#fff;
	font-family:$font-eng;
	padding:7px 0;
	position:relative;
	min-width:1000px;
}
header #head_bar a {
	color:#fff;
	text-decoration:none;
}
header #head_bar p {
	text-align:center;
	font-size:80%;
}
header #head_bar h3 {
	position:absolute;
	right:10px;
	top:7px;
	width:200px;
	text-align:right;
	font-size:80%;
}

@include media-tab {
	header #head_bar {
	padding:12px 0;
	min-width:100%;
}
	header #head_bar h3 {
	display:none;
}

}

/*** logo ***/

header #head_title {
	padding:40px 0 30px 0;
}
header #head_title h1 a {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-decoration: none;
	background-image:url(../images/head_logo.png);
	background-repeat: no-repeat;
	background-position: left top;
	background-size:contain;
	display: block;
	height: 57px;
	width: 147px;
	margin:0 auto;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
header #head_title h1 a {
    background-image:url(../images/head_logo@2x.png);
  }
}
header #head_title h1 a:hover{
	position:relative; 
	top: 1px; 
	left:0px;
}
@include media-tab {
	header #head_title {
	padding:25px 0 20px 0;
}
	header #head_title h1 a {
		width: 100px;
		height: 45px;
	}
}
/*** navi ***/
header nav ul {
	text-align:center;
	font-size:85%;
	letter-spacing:0.1em;
	padding:20px 0 18px;
	width:90%;
	max-width:1200px;
	margin:0 auto;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	header nav ul {
		padding:20px 0 15px;
	}
}

header nav ul li {
	position:relative;
	float:left;
	width:14.285%;
	text-align:center;
}
header nav ul li a {
	color:#000;
	text-decoration:none;
}
header nav ul li a:hover {
	position:relative; 
	top: 1px; 
	left:0px;
	color:#999;
	text-decoration:none;
}
.fixed{
  position: fixed;
  top: 0px;
  z-index: 9999;
  width:100%;
  border-bottom:1px solid #ccc;
  background-color:rgba(255,255,255,0.9);
filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#99ffffff,EndColorStr=#99ffffff);/*IE8以下用*/
}

/*** submenu ***/
header nav ul li ul {
	display:none;
	text-align:center;
	font-family: $gothic;
	font-size:90%;
	letter-spacing:0.1em;
	padding:0px 0;
	width:auto;
	margin:0 auto;
	position:absolute;
	left:0;
	right:0;
	top:16px;
	background-color:rgba(255,255,255,0.9);
filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=0,StartColorStr=#99ffffff,EndColorStr=#99ffffff);/*IE8以下用*/
border:1px solid #ccc;
padding:15px;
z-index:999;
}
header nav ul li ul li {
	float:none;
	width:100%;
	text-align:center;
	padding:5px;
}
@include media-tab {
	header nav {
		display:none;
	}
	.fixed{
		display:none;
	}
}

/********************* FOOTER ********************/
footer {
	min-width:1000px;
}
/*** navi ***/
footer nav ul {
	text-align:center;
	font-size:85%;
	letter-spacing:0.1em;
	padding:20px 0;
	width:90%;
	max-width:1200px;
	margin:0 auto;
}
footer nav ul li a {
	color:#000;
	text-decoration:none;
	float:left;
	width:14.28%;
	text-align:center;
}
footer nav ul li a:hover {
	position:relative; 
	top: 1px; 
	left:0px;
	color:#999;
	text-decoration:none;
}

.float_box {
	width:450px;
}
.float_box2 {
	float:left;
}
.float_box3 {
	float:right;
	width:170px;
	padding:0 0 0 25px;
}
.float_box4 {
	float:left;
}
#footer_info .float_box4 a {
	text-decoration:underline;
}

@include media-tab {
	footer {
	min-width:100%;
}
	footer nav {
		display:none;
	}
	.float_box {
	width:100%;
}
.float_box2 {
	float:none;
}
.float_box3 {
	float:none;
	width:100%;
	padding:0 0 0 0;

}
.float_box4 {
	float:none;
}

}
/*** footer_info ***/
#footer_info {
	background:#000;
	color:#fff;
	padding:50px 0;
}
#footer_info a {
	color:#fff;
	text-decoration:none;
}
#footer_info a:hover {
	color:#fff;
	text-decoration:underline;
}
footer .wrapper {
	max-width:$max-width-l;
	margin:0 auto;
	text-align:left;
}
footer .image {
	float:left;
	width:34%;
}
footer .text {
	float:right;
	width:58%;
}

footer h2 {
	font-family: $font-eng;
	font-size:130%;
	margin-bottom:20px;
	letter-spacing:0.2em;
}
footer .sns{
margin:0 0 20px;
ul{
display: flex;
-webkit-flex-wrap: wrap; 
flex-wrap:  wrap;
@include media-tab { 
justify-content: center;
}
}
li{
margin:0 15px 0 0;
font-size: 18px;
}
}
footer .google {
	margin-bottom:20px;
	font-size:70%;
}
footer h3 {
	font-family: $font-eng;
	font-size:100%;
	margin-bottom:5px;
	letter-spacing:0.2em;
}
footer p {
	font-size:80%;
	margin-bottom:20px;
}

footer .copyright {
	font-size:70%;
	letter-spacing:0.2em;
}
@include media-tab {
#footer_info {
	padding:50px 0 60px;
}
footer .wrapper {
	width:84%;
	margin:0 auto;
	text-align:center;
}
footer .image {
	float:none;
	width:200px;
	margin:0 auto 20px auto;
}
footer .text {
	float:none;
	width:100%;
}
footer h2 {
	font-size:110%;
}
footer h3 {
	font-size:90%;
}
footer p {
	font-size:70%;
}
footer .copyright {
	font-size:60%;
}
}
/**************** pagetop ****************/
#pagetop {
	position:fixed;
	right:0px;
	/*bottom:10px;*/
	bottom:52px;
	z-index: 99;
}
#pagetop a {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-decoration: none;
	background-image:url(../images/pageup.png);
	background-repeat: no-repeat;
	background-position: left top;
	background-size:contain;
	display: block;
	/*height: 48px;
	width: 37px;*/
	height: 50px;
	width: 42px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5) {
#pagetop a {
    background-image:url(../images/pageup@2x.png);
  }
}
#pagetop a:hover{
	position:relative; 
	top: -1px; 
	left:0px;
}

/**************** MAIN(second page) *****************/
.main {
	padding-bottom:80px;
	@include media-tab {
		padding-bottom:40px;
}
}

/**************** PAGETITLE *****************/
.main #pagetitle h1 {
	background:#000;
	color:#fff;
	font-family: $font-eng;
	padding:10px;
	font-size:150%;
	letter-spacing:0.1em;
}
.main #pagetitle nav {
	font-family: $font-eng;
	background:#e6e6e6;
	padding:20px;
	text-align:center;
}
.main #pagetitle nav li {
	display:inline-block;
	font-size:90%;
	padding:0 20px;
}
.main #pagetitle nav li a {
	color:#000;
	text-decoration:none;
}
.main #pagetitle nav li a:hover {

opacity:0.8;
filter: alpha(opacity=80);
-ms-filter: "alpha( opacity=80 )";
}

@include media-tab {
	.main #pagetitle nav {
	padding:5%;
}
	.main #pagetitle nav li {
	display:block;
	font-size:80%;
	padding:5px 0px;
	float:left;
	width:33%;
}
}

/**************** 404 ****************/
#error {
	margin-top:60px;
}
#error h2 {
	font-weight:normal;
	margin-bottom:40px;
}
#error p{
	margin-bottom:40px;
	font-size:90%;
	line-height:200%;
	
}
#error .bt {
	text-align:center;
}
#error .bt a {
	background:#000;
	color:#fff;
	text-decoration:none;
	font-size:100%;
	padding:10px 0;
	display:inline-block;
	width:250px;
	/*全体角丸*/
border-radius: 20px; /* CSS3草案 */ 
-webkit-border-radius: 20px; /* Safari,Google Chrome用 */ 
-moz-border-radius: 20px; /* Firefox用 */ 
}
@include media-tab {
	#error {
	margin:50px 8%;
	text-align:left;
}
}

/**************** RAF ****************/
.raf {
	width:1200px;
	margin:0 auto;
}
@include media-tab {
	.raf {
width:100%;	
	}
}

/**************** bn_onlineshop ****************/
.bn_shop a{
	background:#000;
	font-family: $font-eng;
	color:#fff;
	text-decoration:none;
	font-size:100%;
	letter-spacing: 0.2em;
	margin: -10px 0 0 20px;
	padding:10px 0;
	display:inline-block;
	width:160px;
	border:1px solid #1B1B1B;
}
.bn_shop a:hover{
	background:#fff;
	color:#000;
	font-weight:bolder;
	border:1px solid #1B1B1B;
	
}
@include media-tab {
.bn_shop a{
	width:80%;
	margin-bottom: 30px;
}
}